<template>
  <div>
    <window-header></window-header>
    <nav style="justify-content: space-between">
      <button
        class="button button-fill"
        :class="{ disabled: activeTab == 0 }"
        @click="$refs['main-tabs'].prevTab()"
      >
        <fa-icon :icon="['fas', 'chevron-left']" />
        <span>{{ $t("back") }}</span>
      </button>
      <button
        class="button button-fill"
        @click="$refs['main-tabs'].nextTab()"
        v-if="activeTab < 1"
      >
        <span>{{ $t("next") }}</span>
        <fa-icon :icon="['fas', 'chevron-right']" />
      </button>
    </nav>
    <section>
      <article style="height: 536px; width: 975px">
        <r-tabs
          ref="main-tabs"
          :wt="formData.wt"
          style="top: 0px; left: 0px; height: 535px"
          :buttons="['1. Selección/acotación', '2. Pediente de facturar']"
          :styleButton="'width:50%;'"
          @active="onActiveTab"
        >
          <div class="tab">
            <filtro-seleccion-acotacion
              ref="fsa"
              style="left: 120px; top: 80px"
              referencia="gestionar_reparaciones"
              model="reparacion"
              nombre="Gestionar reparaciones"
            />
          </div>
          <div class="tab">
            <div class="column-container">
              <div
                v-for="c in Object.keys(columns)"
                :key="'columns-' + c"
                class="column"
                :class="'column-' + c"
              >
                <div :class="'kanban-card-container'">
                  <div class="kanban-card-column-header">
                    {{ columns[c].name }}
                  </div>
                  <Container
                    group-name="col"
                    @drop="(e) => onCardDrop(c, e)"
                    :get-child-payload="getCardPayload(c)"
                    drag-class="kanban-card-ghost"
                    drop-class="kanban-card-ghost-drop"
                    :drop-placeholder="dropPlaceholderOptions"
                    :get-ghost-parent="getGhostParent"
                    style="height: 415px; overflow: auto"
                  >
                    <Draggable
                      v-for="card in columns[c].children"
                      :key="card.id"
                    >
                      <div :class="'kanban-card'" :style="card.propsstyle">
                        <div class="kanban-card-image">
                          <img
                            width="100%"
                            :src="
                              card.thumbnail_antes ||
                              require('./../assets/noimage.png')
                            "
                          />
                        </div>
                        <div class="kanban-card-inner">
                          <div class="kanban-card-header">
                            <span class="kanban-card-code">Nº {{ card.codigo }}</span>
                            <span class="kanban-card-price">{{
                              $utils.misc.formatNumber(card.total)
                            }}</span>
                          </div>
                          <p class="kanban-card-text">
                            {{
                              card.cliente_id
                                ? card.cliente_id.nombre_comercial
                                : ""
                            }}
                          </p>
                        </div>
                      </div>
                    </Draggable>
                  </Container>
                </div>
              </div>
            </div>
          </div>
        </r-tabs>
      </article>
    </section>
  </div>
</template>
<script>
import WindowMixin from "./../components/WindowMixin.vue";
import rFormMixin from "./../components/rFormMixin.vue";
import FiltroSeleccionAcotacion from "./../components/FiltroSeleccionAcotacion.vue";
import { Container, Draggable } from "vue-smooth-dnd";

export default {
  mixins: [WindowMixin, rFormMixin],
  components: { FiltroSeleccionAcotacion, Container, Draggable },
  data: function () {
    return {
      title: "Gestionar reparaciones",
      dbAdapter: "reparacion",
      primary: "codigo",
      mode: "new",
      activeTab: 0,
      dropPlaceholderOptions: {
        className: "drop-preview",
        animationDuration: "150",
        showOnTop: true,
      },
      columns: {
        pendiente: { name: "Pendientes", children: [] },
        enviada: { name: "Enviadas", children: [] },
        finalizada: { name: "Finalizadas", children: [] },
        entregada: { name: "Entregadas", children: [] },
      },
    };
  },
  methods: {
    getGhostParent() {
      return document.body;
    },
    onActiveTab(i) {
      var self = this;
      self.activeTab = i;
      switch (i) {
        case 1:
          Object.keys(self.columns).forEach(
            (e) => (self.columns[e].children = [])
          );
          var filter = self.$refs.fsa.getFilter();
          window.DB.getList("reparacion", {
            fields: [
              "estado",
              "thumbnail_antes",
              "total",
              { name: "cliente_id", fields: ["nombre_comercial", "codigo"] },
            ],
            filter,
          }).then((res) => {
            res.data.forEach((r) => {
              self.columns[r.estado].children.push(r);
            });
          });
          break;
      }
    },
    onCardDrop(columnId, dropResult) {
      var self = this;
      if (dropResult.removedIndex !== null)
        self.columns[columnId].children.splice(dropResult.removedIndex, 1);
      if (dropResult.addedIndex !== null) {
        self.columns[columnId].children.splice(dropResult.addedIndex, 0, {
          ...dropResult.payload,
        });
        window.DB.put("reparacion", {
          itemId: dropResult.payload.codigo,
          data: { estado: columnId },
        });
      }
    },
    getCardPayload(columnId) {
      var self = this;
      return (index) => {
        return self.columns[columnId].children[index];
      };
    },
  },
};
</script>
<style>
.column-pendiente .kanban-card {
  background: #ffffee;
}
.column-enviada .kanban-card {
  background: #ffefd6;
}
.column-finalizada .kanban-card {
  background: #e7f4ff;
}
.column-entregada .kanban-card {
  background: #e7f9e7;
}
.kanban-card {
  display: flex;
}
.kanban-card-inner {
  margin: 0 3px 0 5px;
  width: 100%;
}
.kanban-card-header {
  margin-bottom: 0px;
}
.kanban-card-code {
  font-weight: bold;
}
.kanban-card-price {
  float: right;
}
.kanban-card-image {
  width: 80px;
}
.kanban-card-image img {
  border: 1px solid #ccc;
  display: block;
}
.kanban-card-text {
  margin: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
}
.column-container {
  display: table;
  position: relative;
  width: 100%;
  table-layout: fixed;
}
.column {
  width: 25%;
  padding: 10px;
  display: table-cell;
  vertical-align: top;
}
.kanban-card-container {
  height: 470px;
  position: relative;
}

/* TODO: bug ordenar_colecciones */
.draggable-item {
  height: 50px;
  line-height: 50px;
  margin-bottom: 2px;
  margin-top: 2px;
  user-select: none;
}
.draggable-item,
.draggable-item-horizontal {
  text-align: center;
  display: block;
  background-color: #fff;
  outline: 0;
  border: 1px solid rgba(0, 0, 0, 0.125);
  cursor: default;
}
.draggable-item-horizontal {
  height: 300px;
  padding: 10px;
  line-height: 100px;
  margin-right: 4px;
}
.dragging {
  background-color: #ff0;
}
.kanban-card-scene {
  padding: 50px;
}
.kanban-card-container {
  width: 100%;
  background-color: #f3f3f3;
}
.kanban-card,
.kanban-card-container {
  padding: 5px;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.12), 0 1px 1px rgba(0, 0, 0, 0.24);
}
.kanban-card {
  margin: 3px;
  background-color: #fff;
}
.kanban-card-column-header {
  font-size: 18px;
  text-transform: uppercase;
  text-align: center;
  margin-bottom: 10px;
}
.column-drag-handle {
  /*cursor: move;
  padding: 5px;*/
  text-transform: uppercase;
}
.kanban-card-ghost {
  transition: transform 0.18s ease;
  transform: rotate(5deg);
}
.kanban-card-ghost-drop {
  transition: transform 0.18s ease-in-out;
  transform: rotate(0deg);
}
.opacity-ghost {
  transition: all 0.18s ease;
  opacity: 0.8;
  background-color: #6495ed;
  box-shadow: 3px 3px 10px 3px rgba(0, 0, 0, 0.3);
}
.opacity-ghost-drop {
  opacity: 1;
  background-color: #fff;
  box-shadow: 3px 3px 10px 3px transparent;
}
.form-demo {
  width: 650px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 50px;
  display: flex;
}
.form {
  flex: 3;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 6px;
  box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.08), 0 3px 3px rgba(0, 0, 0, 0.08);
}
.form-fields-panel {
  flex: 1;
  margin-right: 50px;
}
.form-ghost {
  transition: 0.18s ease;
  box-shadow: 1px 1px 5px 2px rgba(0, 0, 0, 0.08);
}
.form-ghost-drop {
  box-shadow: 0 0 2px 5px transparent;
}
.drop-preview {
  margin: 5px;
}
.kanban-cards-drop-preview,
.drop-preview {
  background-color: rgba(150, 150, 200, 0.1);
  border: 1px dashed #abc;
}
.kanban-cards-drop-preview {
  margin: 5px 45px 5px 5px;
}
</style>
